
import { auth } from '@/firebase';
import { Component, VModel, Vue } from 'vue-property-decorator';

@Component
export default class LogoutDialog extends Vue {
  @VModel({ required: true }) show!: boolean;

  loading = false;

  async logout(){
    this.loading = true;
    await auth()?.signOut()
    location.reload();
  }
} 
